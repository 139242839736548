import React from "react";
import "./Signature.css";
import wpt from "../../assets/images/watercolor-paper-texture.jpg"

const Signature = () => (
  <div style={{ backgroundImage: `url(${wpt})`}}>
    <div className="text-container" style={{ width: "80%", paddingLeft: 0, paddingTop:"6rem", margin: "0 auto", fontSize: "1.5rem" }}>
      <h2 className="sigt">Wszystkim firmom udostępnie moje patenty</h2><h2 className="sigt"> aby produkowały moje wynalazki dla ludzkości całego świata!</h2>
      <br />
      <h3>A oto zestaw moich osiągnięć:</h3>
      <ul class="dashed">
        <li>Złoty Krzyż Zasługi nadany przez Pana Prezydenta Rzeczypospolitej Polskiej Andrzeja Dudę</li>
        <li>4 nagrody w konkusie - Świętokrzyski Racjonalizator</li>
        <li>2 nagrody w konkusie - Start-Up-Med</li>
        <li>Świętokrzyska Wiktoria</li>
        <li>16 patentów najważniejsze z nich to:
          <ul style={{ listStyle: "none", fontSize: "90%" }}>
            <li>Endoproteza stawu biodrowego</li>
            <li>Stabilizator do leczenia krązków międzykegowych</li>
            <li>Stabilizator do leczenia skolioz kręgosłupa</li>
            <li>Opryskiwacz do warzyw</li>
            <li>Opryskiwacz do sadów</li>
          </ul>
        </li>
        <li>Nagroda zdobyta w konkursie - Innowacyjny Produkt Ogrodniczy w kategorii: Maszyny</li>
        <li>Zostałem umieszczony w Encyklopedii Osobistości Rzeczypospolitej Polskiej wydanie 10, które zostało wydane w grudniu 2024r</li>
      </ul>
      <p className="sig">Z poważaniem</p>
      <p className="sig signature">Stanisław Radoń</p>
      <br/>
    </div>
  </div>
);

export default Signature;

